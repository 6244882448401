import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContaxt";
import { getServer } from "../../services/server";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { Link } from "react-router-dom";
import Analytics, { EventTracker } from "../../services/Analytics";
import Skeleton from "react-loading-skeleton";
import { Redirect, useParams } from 'react-router-dom'

export default function List() {
  const { org } = useParams();
  const company = useContext(CompanyContext);
  const [pageData, setPageData] = useState();
  const [nothingToSee, setNothingToSee] = useState(false);
  const [transform, setTransform] = React.useState(
    "translate3d(0," +
    (window.innerWidth >= 768 ? window.pageYOffset / 3 : 0) +
    "px,0)"
  );
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-posts");
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      setTransform("translate3d(0," + windowScrollTop + "px,0)");
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  }, []);


  useEffect(() => {
    async function fetchData() {
      if (company && org) {
        const response = await getServer(`/galleries/byCompany/byOrg/${company._id}/${org}`);
        if (!response?.org) {
          setNothingToSee(false);
        } else {
          setNothingToSee(false);
        }
        setPageData(response);
      }
    }
    Analytics();
    fetchData();
  }, [company, org]);


  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  const tracker = (props) => {
    EventTracker({
      category: 'Gallery',
      action: `Clicked on ${props.name} Gallery`,
      label: moment(props.gallery_date).format('ll')
    })
  }

  // if (nothingToSee) {
  //   return <Redirect to="/" />;
  // }


  const GalleryPost = data => {
    let firstImage = data.items[0];

    return (
      <Col lg="4" md="6">
        <Card className="card-blog card-plain">
          <div className="card-image">
            <Link to={`/gallery/${org}/${data.slug}`}>
              <img
                alt="..."
                className="img rounded"
                src={data.cover || firstImage}
              />
            </Link>
          </div>
          <CardBody>
            <CardTitle tag="h4">
              <Link to={`/gallery/${org}/${data.slug}`} onClick={() => tracker({ name: data.name, gallery_date: moment(data.gallery_date).format('ll') })}>
                {data.name} - {moment(data.gallery_date).format('ll')}
              </Link>
            </CardTitle>
            <CardFooter>
              <div className="author">
                <img
                  alt="..."
                  className="avatar img-raised"
                  src={data?.organization?.logo}
                />
                <span className="ml-1">{data?.organization?.name}</span>
              </div>
              <div className="stats stats-right">
                <i className="tim-icons icon-watch-time" /> Uploaded {moment(data.date_added).fromNow()}
              </div>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    )
  }

  const GalleryLoading = () => {
    return (
      <Col lg="4" md="6">
        <Card className="card-blog card-plain">
          <div className="card-image">
            <Skeleton height={'100%'} width={'100%'} count={1} />
          </div>
          <CardBody>
            <CardTitle tag="h4">
              <Skeleton />
            </CardTitle>
            <CardFooter>
              <div style={{ width: '100%' }} className="">
                <Skeleton className="margin-right-10" inline={true} height={30} width={30} />
                <Skeleton className="margin-right-20" width={50} inline={true} />
                <Skeleton width={150} inline={true} />
                <span className="ml-1"></span>
              </div>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    )
  }




  return (
    <>
      <NavBar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1695348108199_@Crispy.Raw-6556_Large.jpeg)",
              transform: transform
            }}
          />
          <div className="content-center">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                {nothingToSee ? (
                  <h1 style={{ fontFamily: 'mainFont', fontSize: 55, marginBottom: 5, marginTop: 10 }} className="title text-white">
                    No Photos at this time.
                  </h1>
                ) : (
                  <>
                    <img src={pageData?.org?.logo} alt="logo" width="150" className="gallery-logo" />
                    <h1 style={{ fontFamily: 'mainFont', fontSize: 55, marginBottom: 5, marginTop: 10 }} className="title text-white">
                      {pageData?.org?.name} Gallery
                    </h1>
                    <h3 style={{ marginBottom: 0 }} className="text-white">
                      {pageData?.org?.biLine}
                    </h3>
                    <h4 className="text-white">
                      {pageData?.org?.dates}
                    </h4>
                  </>
                )}

              </Col>
            </Row>
          </div>
        </div>
        <div className="main main-raised my-gallery">
          <Container>
            <Row>

              {pageData?.galleries && Array.isArray(pageData?.galleries) ? (
                <>
                  {
                    pageData?.galleries.map((item, key) => <GalleryPost key={key} {...item} />)
                  }
                </>
              ) : null}
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
