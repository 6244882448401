import React, { useContext, useEffect, useState } from "react";
// ReactJS plugin for a nice carousel
import { useParams } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Analytics from "../../services/Analytics";
import { getServer, postServer } from "../../services/server";
import moment from "moment";
import TicketCart from "../../components/TicketCart";
import { CompanyContext } from "../../contexts/CompanyContaxt";
import ticketPriceMinMax from "../../helpers/ticketPriceMinMax";
import { AffContext } from "../../contexts/AffiliateContext";
import trackingLink from "../../services/trackingLink";

export default function Event() {
  const { slug } = useParams();
  const { aff } = useContext(AffContext);
  const company = useContext(CompanyContext);
  const [show, setShow] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageData, setPageData] = useState({});
  const wrapper = React.useRef(null);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const [displayTicketPrices, setDisplayTicketPrices] = useState({}); // minPrice, maxPrice
  const [eventLink, setEventLink] = useState('');
  const [didAff, setDidAff] = useState(false);
  const [affId, setAffId] = useState(null);

  function getURLParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  useEffect(() => {
    async function sendData() {
      
      try {
        if (aff && pageData?._id) {
          await trackingLink(pageData?._id, aff).then(async (res) => await setAffId(res?._id || null));
          setDidAff(true);
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    sendData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aff, pageData]);

  console.log('affId', affId);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);

  //   {
  //     "promo": {
  //         "story": "https://sfclubs.s3-us-west-1.amazonaws.com/promoFlyers/1693180575638_1692677559731_IMG_5232.jpg",
  //         "banner": "https://sfclubs.s3-us-west-1.amazonaws.com/promoFlyers/1693180575638_1692677536652_IMG_5233.jpg"
  //     },
  //     "private": false,
  //     "active": true,
  //     "approvedAffiliates": [],
  //     "_id": "64ebe29f6ea77c10e1db6e1e",
  //     "company": "61e2056c65fba2004e536f70",
  //     "organization": {
  //         "status": true,
  //         "active": true,
  //         "_id": "643085b318c86d0c7a62ea89",
  //         "name": "Club X",
  //         "address": "715 Harrison St",
  //         "city": "San Fransico",
  //         "state": "CA",
  //         "zip": "95134",
  //         "company": "61e2056c65fba2004e536f70",
  //         "__v": 0,
  //         "logo": "https://sfclubs.s3.us-west-1.amazonaws.com/logos/logoLight.png",
  //         "slug": "club-x",
  //         "biLine": "Top40's, EDM, Club Hits and Open Format Sets",
  //         "dates": "Every Saturday",
  //         "age": "18+"
  //     },
  //     "name": "Student Night",
  //     "start_date": "2024-02-01T04:00:00.000Z",
  //     "end_date": "2024-02-01T09:00:00.000Z",
  //     "ticket_link": "https://www.eventbrite.com/e/clubx-out-of-this-world-tickets-710712619577",
  //     "details": "<p><strong>CLUB X SATURDAY'S: PLAYBOY PARTY</strong></p><ul><li>DJ Jimmy Hits Main Room</li><li>DJ EDEN ROC</li><li>BIGGEST COLLEGE PARTY IN THE BAY!</li><li>EDM TOP 40 CLUB HITS</li><li>BLACK &amp; GOLD BALLOON DROP</li><li>PLAYBOY BUNNY EARS GIVEAWAY</li><li>18+ TO ENTER| 21+ TO DRINK .</li><li>DRINK SPECIAL $2 VITAMIN WATER &amp; $6 DRINK SPECIAL</li><li>Get in for $10 before 10:30 pm with student ID</li></ul><p><br></p><p><strong>VIP Packages (Available in the Ticket Section)</strong>:-</p><p>Mezzanine VIP Booth - (Seats 6 - 8) - $399 - *Includes 4 admission tickets-</p><p>Platinum VIP Booth - (Seats 6 - 8) - $599 - *Includes 6 admission tickets</p><p>**By purchasing ticket(s), you agree to be added to our Club X email newsletter. You can opt out at any time.</p><p><br></p><p>Parking | Directions:&nbsp;We're located right off the freeway when you get over the bay bridge, just take the Harrison St. Exit and take a left onto Harrison St. There are multiple parking lots on the intersection of 3rd Street and Harrison Street. Plus, street parking is free in San Francisco after 6PM.About Club X is an 18+ event that takes place every Saturday at 715 Harrison St. in San Francisco from 10:00PM - 2:30AM. The venue is 15,000 square feet (a basketball court is 4,700 square feet, for reference). It contains two dance floors, along with two levels (upstairs and downstairs). Each dance floor has its own DJ, and both DJs spin EDM, Top 40 &amp;. Dubstep &amp; Club Hits throughout the night.</p>",
  //     "slug": "student_night1693180575572",
  //     "date_added": "2023-08-27T23:56:15.572Z",
  //     "__v": 0,
  //     "ticketinventory": [
  //         {
  //             "soldQuantity": 2,
  //             "price": 10,
  //             "pay_prossesing_fee": false,
  //             "created_date": "2023-10-16T05:46:14.922Z",
  //             "deleted": false,
  //             "active": true,
  //             "_id": "652cce858c6f3d9c32cdf407",
  //             "ticketName": "VIP",
  //             "ticketDescription": "0",
  //             "totalQuantity": 100,
  //             "startDate": "2023-10-16T05:47:19.657Z",
  //             "event": "64ebe29f6ea77c10e1db6e1e",
  //             "__v": 0
  //         }
  //     ]
  // }

  useEffect(() => {
    Analytics();
    async function getData() {
      try {
        const response = await getServer(`/events/getEvent/${slug}`);
        if (response) {
          setPageData(response);
          setCurrentTicket(response);
          setEventLink(response?.ticket_link);
          setDisplayTicketPrices(ticketPriceMinMax(response?.ticketinventory || []));
          const link = getURLParameter('aff');
          let mainlink = response?.ticket_link;
          let newlink;
          if (link) {
            let newlink = mainlink + '?aff=' + link;
            setEventLink(newlink);
          } else {
            newlink = '?aff=Website';
            setEventLink(newlink);
          }
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    getData();
  }, [slug, aff, pageData?._id]);




  return (
    <>
      <NavBar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header header-filter page-header-small">
          <div
            className="page-header-image"
            data-parallax={true}
            style={{
              backgroundImage: `url(${pageData?.promo?.banner})`
            }}
          />
        </div>
        <div className="section">
          <Container>
            <Row>
              <Col md={3}>
                <div className="card-image">
                  <a href="#!">

                    {
                      pageData?.promo?.video ? (
                        <video autoPlay muted loop playsInline className="VideoFlyer">
                          <source src={pageData?.promo?.video} type="video/mp4" />
                        </video>
                      ) : (<img className="img rounded" src={pageData?.promo?.story || pageData?.promo?.post} alt={pageData?.name} />)
                    }

                  </a>
                </div>
              </Col>
              <Col className="ml-auto mr-auto margin-top-40" md="6">
                <Row>
                  <Col md={12}>
                    <h3 style={{ paddingBottom: 5 }} className="event-date text-white">{moment(pageData?.start_date).format('lll')}</h3>
                    <br />
                    <h1 className="card-title">
                      {pageData?.link && pageData?.link !== '' ? (
                        <a target="_BLANK" href={pageData?.link} rel="noreferrer" style={{ fontFamily: 'mainFont' }}>{pageData?.organization?.name} - {pageData?.name}</a>
                      ) : (
                        <a href="#!" style={{ fontFamily: 'mainFont' }}>{pageData?.organization?.name} - {pageData?.name}</a>
                      )}
                    </h1>
                  </Col>
                  <Col md={12}>
                    {/* {pageData?.details} */}
                    <div className="event-details">
                      <div dangerouslySetInnerHTML={{ __html: pageData?.details }} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3} className="margin-top-40">
                <Card
                  body
                  className="my-2"
                >

                  {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                    <>
                      <h1 style={{ fontWeight: 800 }} className="text-white">
                        <span style={{ fontSize: 14, fontWeight: 200 }}>Starting at</span> <br /> ${displayTicketPrices.minPrice} - ${displayTicketPrices.maxPrice}
                      </h1>
                    </>

                  ) : (
                    <>
                      {pageData.ticket_link ? (
                        <h1 style={{ fontWeight: 800 }} className="text-white">
                          <span style={{ fontSize: 18, fontWeight: 200 }}>Link to Tickets</span>
                        </h1>
                      ) : null}
                    </>
                  )}
                  {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                    <TicketCart
                      show={show}
                      event={pageData}
                      handleClose={handleClose}
                      handleShow={handleShow}
                      currentTicket={currentTicket}
                      company={company}
                      ticketQuantities={ticketQuantities}
                      setTicketQuantities={setTicketQuantities}
                      affId={affId}
                    />
                  ) : (
                    <>
                      {pageData.ticket_link ? (
                        <a style={{ width: '100%' }} target="_BLANK" href={pageData?.ticket_link} rel="noreferrer">
                          <Button color="primary" block>Tickets</Button>
                        </a>
                      ) : <Button color="secondary" disabled block>Unavailable</Button>}
                    </>
                  )}
                </Card>
                <div className="author text-center">
                  <div>
                    <div style={{ color: 'white', fontSize: 15 }}><span style={{ fontWeight: 'bold', fontSize: 18, color: 'red', marginRight: 3 }}>{pageData?.organization?.age}</span> {moment(pageData?.start_date).format('lll')}</div>
                  </div>
                  <address>
                    <a href={`//maps.apple.com/?q=${pageData?.organization?.address}},${pageData?.organization?.city},${pageData?.organization?.state},${pageData?.organization?.zip}`}>
                      {pageData?.organization?.address},<br />
                      {pageData?.organization?.city} {pageData?.organization?.state}, {pageData?.organization?.zip}
                    </a>
                  </address>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="section">
          <Container>
            <h1 style={{ fontFamily: 'mainFont' }}> Recent Gallery </h1>
            <Slick {...slickSettings}>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/mark-harrison.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/steven-roe.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/rezaul-karim.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/pawel-nolbert.jpg")}
                  width="450"
                />
              </div>
              <div>
                <img
                  alt="..."
                  height="500"
                  src={require("../../assets/img/serge-kutuzov.jpg")}
                  width="450"
                />
              </div>
            </Slick>
          </Container>
        </div> */}
        <Footer />
      </div >
    </>
  );
}
