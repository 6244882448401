import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { EventTracker } from '../services/Analytics';
import moment from 'moment';
import {history} from '../services/history';

export default function PromoModal({ modal, toggle, modalData }) {
  const [eventLink, setEventLink] = useState('');

  function getURLParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  useEffect(() => {
    const link = getURLParameter('aff');
    let newlink;
    if (link) {
      newlink = '?aff=' + link;
      setEventLink(newlink);
    } else {
      newlink = '?aff=Website';
      setEventLink(newlink);
    }
  }, []);

  function goToEventRoute(url) {
    // window.open(url.ticket_link, '_blank');
    history.push(`/events/event/${url.slug}`);
    toggle()
  }

  const tracker = (props) => {
    EventTracker({
      category: 'Events',
      action: `Clicked on ${props.name} tickets`,
      label: props.event_date
    })
  }

  return (
    <>
      <div className='promo-modal'>
        <Modal
          show={modal}
          backdrop={true}
          onHide={toggle}
          keyboard={true}
          centered={true}
          size="lg"
          contentClassName="no-box-shadow"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body className="no-box-shadow" style={{ paddingTop: 30 }}>
            <Row>
              {modalData.map((item, index) => (
                <Col key={index} className="text-center">
                  <img style={{ maxHeight: 800 }} src={item?.promo.story || item?.promo.post} alt="Popup" />
                  <div className='popup-modal-footer margin-top-10'>
                    <Button variant="secondary" size="sm" onClick={toggle}>
                      No Thanks
                    </Button>
                    <Button size="sm" onClick={() => {
                      tracker({ name: item.name, event_date: moment(item.start_date).format('lll') });
                      goToEventRoute(item);
                    }} variant="primary">Tickets</Button>
                  </div>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
