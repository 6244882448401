import React from 'react'
import Footer from '../components/Footer'
import MainHeader from '../components/headers/MainHeader'
import NavBar from '../components/NavBar'
import RentalSection from '../components/RentalSection'
import Subscribe from '../components/Subscribe'
import Analytics from '../services/Analytics'
export default function LandingPage() {

  React.useEffect(() => {

    Analytics()
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <NavBar />
      <MainHeader />
      <RentalSection />
      <Subscribe />
      <Footer />
    </>
  )
}
