import React from 'react'
import Slick from "react-slick";
import { Slide } from 'react-slideshow-image';
import ReactPlayer from 'react-player/lazy';
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GiMorgueFeet } from "react-icons/gi";
import { BiDrink } from "react-icons/bi";
import Analytics, { EventTracker } from '../../services/Analytics';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Media,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ContactMap from '../../components/ContactMap';
import { Link } from 'react-router-dom';
import moment from 'moment';

// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

let slickSettings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: "slider col-md-8 ml-auto mr-auto",
  dots: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};


function Rental() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    Analytics()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  
  const facts = [
    'Consisting of three bars',
    'Two dance floors',
    'Rane 68 DJ Mixer',
    '2 Technic 1200 M3S',
    '2 EAW booth monitors',
    '2 Pioneer DVJ-X1',
    '2 Shure wireless mics',
    '1 headset/lapel mic',
    '24 Elation Design Spot 300 Pros',
    '4 High end Technobeams',
    '2 strobe lights',
    '10 led par/bar',
    '2 fog machines',
    '1 video projector'
  ];

  const images = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  ];

  const fakeNumbers = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]

  return (
    <>
      <NavBar />
      <div className="wrapper" ref={wrapper}>
        <div className="page-header header-filter">
          <div
            className="page-header-image"
            data-parallax={true}
            style={{
              backgroundImage:
                "url(" + require("../../assets/img/rental/1.jpg") + ")"
            }}
          />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title x4">715 HARRISON</h1>
              </Col>
            </Row>
          </Container>
        </div>



        <div className="section about-description">
          <img
            alt="..."
            className="path path4"
            src={require("../../assets/img/path4.png")}
          />
          <Container>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-primary">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/primary.png")}
                    />
                    <GiMorgueFeet size={'3em'} />
                  </div>
                  <h4 className="info-title">15,000 Square Feet</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/success.png")}
                    />
                    {/* <i className="tim-icons icon-atom" /> */}
                    <AiOutlineUsergroupAdd size={'3em'} />
                  </div>
                  <h4 className="info-title">1,200 Person Capacity</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("../../assets/img/feature-blob/warning.png")}
                    />
                    <BiDrink size={'3em'} />
                  </div>
                  <h4 className="info-title">Two Rooms | Dance Hall | Three Bars</h4>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="mb-5">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h4 className="description">
                  <b>Our passion is being a part of the San Francisco cultural scene.</b><br />
                  We embody San Francisco’s quirkiness of juxtapositions. We are organized people who understand what it means to live a little.
                </h4>
                <br />
                <h4 className="description">
                  <b>We eat crispy tacos under decadent chandeliers.</b><br />
                  We have a history of facilitating memorable events for all walks of life. There is no client too large for us or too small. We are good people and have a special place in our hearts for non-profits and students.
                </h4>
                <br />
                <h4 className="description">
                  Event planning, venue rental, private parties, corporate events, holiday parties, fitness events, wine tastings, launch parties, state-of-the-art lighting & sound, catering, coordination, creation, production, planning & logistics. All with an open mind.
                </h4>
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto text-center player-wrapper" md="7">
                <ReactPlayer
                  className='react-player'
                  url='https://vimeo.com/165053515'
                  width='100%'
                  height='100%'
                />
              </Col>
            </Row>
          </Container>
          {/* <Container>
            <Row>
              <Col>
                <Slick {...slickSettings}>
                  <div>
                    <img
                      alt="..."
                      src={require("../../assets/img/rental/2.jpg")}
                    />
                  </div>
                  <div>
                    <img
                      alt="..."
                      src={require("../../assets/img/rental/3.jpg")}
                    />
                  </div>
                  <div>
                    <img
                      alt="..."
                      src={require("../../assets/img/rental/4.jpg")}
                    />
                  </div>
                  <div>
                    <img
                      alt="..."
                      src={require("../../assets/img/rental/5.jpg")}
                    />
                  </div>
                  <div>
                    <img
                      alt="..."
                      src={require("../../assets/img/rental/1.jpg")}
                    />
                  </div>
                </Slick>
              </Col>
            </Row>
          </Container> */}
        </div>

        <div className="section about-description">
          <Container>
            <Row className="mb-5">
              <Col className="ml-auto mr-auto text-center" md="8">
                <Link to="/booking">
                  <Button className="btn-round ml-1 big-button" color="primary" type="button">
                    Rental Request
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">
                  715 Harrison
                </h3>
                <p>
                  Located in the heart of San Francisco’s SOMA district, only a few blocks away from Moscone Convention Center. This versatile event space has 15,000 square feet, multiple rooms, two dance floors, three full bars (additional mobile bars are available) and is fully equipped with state-of the-art sound and lighting systems. We staff our own awesome bartenders. We can host events of all ages at our venue, but you must be 21+ with vaild ID to drink.
                  <br />
                  <br />
                  We want you to put your spin on our space. Please contact us to schedule a walk through.
                </p>
                <br />
                <br />
                <p>
                  Need help planning? We are also a full service event planning company that knows our venue like the back of our hand, plus we have relationships with many vendors.
                  <br />
                  <br />
                  <br />
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <img
                  alt="..."
                  className="d-block"
                  src={require("../../assets/img/rental/6.jpg")}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title x2">
                  Quick Facts
                </h3>
                <div className='rental-facts'>
                  <ul>
                    {facts.map((fact, id) => (
                      <li key={id}>
                        <b>{fact}</b>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title margin-top-60">
                  Venue Photo's
                </h3>

                <Slide transitionDuration={200} autoplay={false}>
                  {fakeNumbers.map((img, key) => {
                    let foo = key + 1;
                    return (
                      <div key={key} className="each-slide-effect">
                        <img
                          alt="..."
                          className="d-block"
                          src={`https://sfclubs.s3.us-west-1.amazonaws.com/images/venuePhotos/${foo}.jpg`}
                        />
                      </div>
                    )
                  })}
                </Slide>

              </Col>
            </Row>
          </Container>
        </div>
        <div className='section'>
          <Container>
            <Row>
              <Col>
                <h3 className="title margin-top-60">
                  715 HARRISON CONTACT / LOCATION
                </h3>
                <ContactMap zoom={14} />
                <h5 className='title'>Venue address: 715 Harrison St, San Francisco, CA 94107</h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section about-description">
          <Container>
            <Row className="mb-5">
              <Col className="ml-auto mr-auto text-center" md="8">
                <Link to="/booking">
                  <Button className="btn-round ml-1 big-button" color="primary" type="button">
                    Rental Request
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Rental