import React from 'react'
import { NavLink } from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function RentalSection() {

  return (
    <div className="section blogs-4">
      <Container fluid>
        <h1 className="title text-center text-white">Pick the right option for you</h1>
        <h3 className="description text-center text-white">Thinking about throwing your own event? We offer that to!</h3>
        <br />
        <Row>
          <Col lg="6">
            <Card
              className="card-blog card-background"
              data-animation="zooming"
            >
              <div
                className="full-background"
                style={{
                  backgroundImage:
                    "url(" +
                    require("../assets/images/81592909_2781079705291747_6140814308470161408_n.jpg")
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category text-white">SF Clubs</h6>
                  <NavLink
                    className={''}
                    to="/events"
                  >
                    <h3 className='text-white'>Club X - Biggest 18+ College party in the Bay </h3>
                  </NavLink>
                  
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card
              className="card-blog card-background"
              data-animation="zooming"
            >
              <div
                className="full-background"
                style={{
                  backgroundImage:
                    "url(" +
                    require("../assets/images/INTERNET_20.jpg")
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category text-white">715 Harrison Event Space</h6>
                  <a href="http://715harrisoneventspace.com" target="_BLANK" rel="noreferrer">
                    <h3 className='text-white'>Venue Rental</h3>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </Container>
    </div>
  )
}
