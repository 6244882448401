import React, { useEffect } from 'react'
import NavBar from '../components/NavBar'
import Faqs from '../components/Faq';
import Footer from '../components/Footer';
import Subscribe from '../components/Subscribe';
import Analytics from '../services/Analytics';
import IconStickers from '../components/IconStickers';
import { Row, Col } from 'react-bootstrap';
import SHIRT from '../assets/dressCodeIcons/shirtWhite.png'
import SHORTS from '../assets/dressCodeIcons/shortsWhite.png'
import HAT from '../assets/dressCodeIcons/hatWhite.png'
import JERSEY from '../assets/dressCodeIcons/jerseyWhite.png'
import HOODIE from '../assets/dressCodeIcons/hoodieWhite.png'
import SWEATS from '../assets/dressCodeIcons/sweatsWhite.png'
import FACE from '../assets/dressCodeIcons/faceWhite.png'
import BACKPACK from '../assets/dressCodeIcons/backpackwhite.png'
import COLLARED from '../assets/dressCodeIcons/collaredWhite.png'
import POLO from '../assets/dressCodeIcons/poloWhite.png'
import DRESS from '../assets/dressCodeIcons/dressWhite.png'
import PANTS from '../assets/dressCodeIcons/pantsWhite.png'
import SHOES from '../assets/dressCodeIcons/shoesWhite.png'
import HEELS from '../assets/dressCodeIcons/heelsWhite.png'

export default function Faq() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Analytics();
  }, []);

  return (
    <>
      <NavBar />
      <div className="page-header header-filter">
        <div
          className="page-header-image"
          data-parallax={true}
          style={{
            backgroundImage: `url(https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1703798653509_K79A4266_Large.jpeg)`
          }}
        />
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h1 style={{ fontFamily: 'mainFont', fontSize: 55, marginBottom: 5, marginTop: 10 }} className="title text-white">
                Frequently Asked Questions
              </h1>
              <h3 style={{ marginBottom: 0 }} className="text-white">
                + Dress Code
              </h3>
            </Col>
          </Row>
        </div>
      </div>
      <div className="contactus-4">
        <div className="container">
          <Row>
            <Col md={12}>
              <div align="left">
                <h4 style={{fontSize:35}} className="text-white"><strong>Required for Entry</strong></h4>
                <ul>
                  <li className="text-white faq-p">Government Issued ID</li>
                  <li className="text-white faq-p">We do not accept the following as IDS: expired or punched ID, picture of ID</li>
                  <li className="text-white faq-p">Counterfeit IDS will be confiscated</li>
                </ul>
                <h4 style={{fontSize:35, marginTop: 60}} className="text-white"><strong>Dress to Impress</strong></h4>
                <ul>
                  <li className="text-white faq-p">Dress Code Is Fashionable Attire</li>
                  <li className="text-white faq-p">No shorts, hats, jerseys, t-shirts, hoodies, baggy clothing, sweatpants, sports attire, sun glasses, wallet chains, construction boots, or face tattoos</li>
                  <li className="text-white faq-p">No visible gang tattoos, or any gang like appearance allowed</li>
                </ul>
                <p className="text-white faq-p"><strong>Dress code is enforced, guests found in improper attire may be denied entry even with pre-purchased tickets. <em>No exceptions.</em></strong></p>
                <p className="text-white faq-p"><strong>Club X Reserves The Right To Refuse Service To Anyone</strong></p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <center>
                <h1 style={{marginTop: 100 }} className="text-white dress-code-title">NOT ALLOWED:</h1>
              </center>
              <Row>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="SHORTS" image={SHORTS} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="HAT" image={HAT} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="JERSEY" image={JERSEY} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="SHIRT" image={SHIRT} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="HOODIE" image={HOODIE} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="SWEATS" image={SWEATS} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="FACE TATS" image={FACE} /></Col>
                <Col md={3} style={{ marginBottom: 40 }}> <IconStickers name="BACK PACK" image={BACKPACK} /></Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={12}>
            </Col>
            <Col md={8}>
              <center>
                <h1 style={{marginTop: 120}} className="text-white dress-code-title">RECOMMENDED:</h1>
              </center>
              <Row>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="COLLARED" image={COLLARED} /></Col>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="POLO" image={POLO} /></Col>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="DRESS" image={DRESS} /></Col>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="PANTS" image={PANTS} /></Col>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="SHOES" image={SHOES} /></Col>
                <Col md={4} style={{ marginBottom: 40 }}> <IconStickers name="HEELS" image={HEELS} /></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Faqs />
            </Col>
          </Row>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  )
}
