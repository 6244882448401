import {getServer} from './server';

async function getCompany () {
    let address = window.location.hostname;
    console.log(window.location.hostname)

    return await getServer('/companies/getByAddress/' + address).then(res => {
        return res || null
    });
}

export default getCompany;