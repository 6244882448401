import React, { useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FiMinusCircle } from 'react-icons/fi';
import { MdArrowBack, MdOutlineAddCircle } from 'react-icons/md';
import stripePaymentModthod from '../assets/images/paymentMethods.png';
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postServer } from '../services/server';
import ApplePay from './ApplePay';
import buildTicketFees from '../helpers/buildTicketFees';
import usdFormatter from '../helpers/usdFormatter';
// import Ticket from './Ticket';

function TicketCart({ show, handleClose, currentTicket, company, ticketQuantities, setTicketQuantities, handleShow, event, affId }) {
  const navigate = useHistory();
  const modalContentRef = useRef(null);
  const [inputValues, setInputValues] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    // scroll to top
    scrollToTop();
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  const handleDecreaseQuantity = (ticketName) => {
    ticketName = ticketName.ticketName;
    const newQuantity = (ticketQuantities[ticketName] || 0) - 1;
    setTicketQuantities((prevQuantities) => ({
      ...prevQuantities,
      [ticketName]: newQuantity >= 0 ? newQuantity : 0,
    }));
  };

  const handleIncreaseQuantity = (ticket) => {
    const maxQuantity = ticket.totalQuantity - ticket.soldQuantity;
    const ticketName = ticket.ticketName;
    const currentQuantity = ticketQuantities[ticketName] || 0;
    const newQuantity = Math.min(currentQuantity + 1, maxQuantity, 10);
    setTicketQuantities((prevQuantities) => ({
      ...prevQuantities,
      [ticketName]: newQuantity,
    }));
  };


  const calculateTotalPrice = () => {// Stripe's fixed fee
    let events = [];
    for (const ticketName in ticketQuantities) {
      const quantity = ticketQuantities[ticketName];
      if (quantity > 0) {
        const ticket = currentTicket?.ticketinventory?.find(
          (ticket) => ticket.ticketName === ticketName
        );
        const price = ticket?.price || 0;
        for (let i = 0; i < quantity; i++) {
          events.push({
            ticketName: ticketName,
            price: price
          })
        }
      }
    }
    return { events };
  };


  const { events } = calculateTotalPrice();

  const finalAmounts = buildTicketFees(events, company?.application_fee, 0.029, 0.3);
  const finalAmount = finalAmounts.total;
  const cardElementStyle = {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
      lineHeight: '25px',
    },
    invalid: {
      color: '#9e2146',
    },
  };

  const payBill = async (e) => {
    e.preventDefault();

    if (!inputValues.first_name || inputValues.first_name.length < 1) return toast.warning('Please enter your first name');
    if (!inputValues.last_name || inputValues.last_name.length < 1) return toast.warning('Please enter your last name');
    if (!inputValues.email || inputValues.email.length < 1) return toast.warning('Please enter your email');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${inputValues.first_name} ${inputValues.last_name}`,
        email: inputValues.email,
      },
    });

    if (error) {
      console.log('[error]', error);
      toast.error(error.message);
    } else {
      setIsLoading(true);
      postServer(`/events/buyTickets/${currentTicket._id}`, {
        token: paymentMethod,
        company: company._id,
        first_name: inputValues.first_name,
        last_name: inputValues.last_name,
        email: inputValues.email,
        tickets: ticketQuantities,
        amountDue: finalAmount,
        affId : affId
      }).then((response) => {
        setIsLoading(false);
        toast.success('Ticket(s) has been purchased successfully');
        navigate.push(`/events/confirmation/${response.user}/${response.event}`)
        handleClose();
      }).fail((error) => {
        console.log(error)
        setIsLoading(false);
        toast.error(error?.responseJSON?.payment_intent?.last_payment_error?.message || 'Something went wrong on our end. Ticket(s) has not been purchased. Please try again or contact support.');
      })
    }
  }

  const Totals = () => {
    return (
      <div className="">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <img
              src={currentTicket?.promo?.banner || currentTicket?.promo?.story}
              className="img-fluid"
              style={{ width: '100%' }}
              alt="Avatar"
            />
          </div>

          <p className="small mb-2">Order Summary</p>
          <ul className="list-group list-group-flush">
            {Object.keys(ticketQuantities).map((ticketName, index) => {
              const quantity = ticketQuantities[ticketName] || 0;
              if (quantity > 0) {
                const ticket = currentTicket?.ticketinventory?.find(
                  (ticket) => ticket.ticketName + index === ticketName + index
                );
                const price = ticket?.price || 0;
                const totalPrice = quantity * price;

                return (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 text-black"
                  >
                    {quantity}x {ticketName}
                    <span className="pull-right">${totalPrice.toFixed(2)}</span>
                  </li>
                );
              } else {
                return null;
              }

            })}
          </ul>
          <hr className="my-4" />

          <div className="d-flex justify-content-between">
            <p className="mb-2">Subtotal</p>
            <p className="mb-2">{usdFormatter(finalAmounts.breakdown.tickets)}</p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="mb-2">Fees</p>
            <p className="mb-2">
              {finalAmount > 0.31 ? usdFormatter(finalAmount - finalAmounts.breakdown.tickets) : '$0.00'}
            </p>
          </div>

          <div className="d-flex justify-content-between mb-4">
            <p className="mb-2">Total Due</p>
            <p className="mb-2">{finalAmount > 0.31 ? usdFormatter(finalAmount) : '$0.00'}</p>
          </div>
          {step === 1 && (
            <>
              <button
                onClick={() => {
                  if (finalAmount > 0.30) {
                    handleNext();
                  } else {
                    toast.warning('Please select at least one ticket');
                  }
                }}
                type="button"
                className="btn btn-info btn-block btn-lg">
                <div className="d-flex justify-content-between">
                  <span>{finalAmount > 0.30 ? usdFormatter(finalAmount) : '0.00'}</span>
                  <span>
                    Checkout <i className="fas fa-long-arrow-alt-right ms-2"></i>
                  </span>
                </div>
              </button>
              <ApplePay
                ticketQuantities={ticketQuantities}
                totalAmountDue={finalAmount}
                event={event}
                handleClose={handleClose}
                currentTicket={currentTicket}
                history={navigate}
                affId={affId}
              />
            </>
            // {finalAmount > 0.30 ? usdFormatter(finalAmount) : '0.00'}
          )}
          {step === 2 && (
            <Row>
              <Col xs={12}>

                <Button onClick={payBill} disabled={isLoading} className="btn btn-block w-full mt-1" variant="dark">{isLoading ? (
                  <>
                    <Spinner animation="border" variant="light" size="sm" /> Processing
                  </>
                ) : 'Pay'}</Button>

              </Col>
            </Row>
          )}
        </div>
      </div>
    )
  }

  const CartScreen = () => {
    return (
      <Row id="ticket-cart">
        <div className="col-lg-7">
          <h5 className="mb-3">
            <a href="#!" onClick={handleClose} className="text-body flex items-center">
              <MdArrowBack /> Continue shopping
            </a>
          </h5>
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <p className="mb-1">Available Tickets</p>
              <p className="mb-0">
                There are{' '}
                <b>
                  {currentTicket?.ticketinventory && Array.isArray(currentTicket?.ticketinventory)
                    ? currentTicket?.ticketinventory.length
                    : 0}
                </b>{' '}
                ticket group(s) for you to choose from.
              </p>
            </div>
          </div>

          <Row>
            {currentTicket?.ticketinventory && Array.isArray(currentTicket?.ticketinventory)
              ? currentTicket?.ticketinventory?.map((ticket, index) => {
                const quantity = ticketQuantities[ticket.ticketName] || 0;
                const ticketPrice = ticket?.price || 0;

                return (
                  <Col key={index} xs={12}>
                    <Card>
                      <Card.Header>
                        <Row>
                          <Col xs={6}>
                            <div className="card-title text-black">{ticket.ticketName}</div>
                          </Col>
                          <Col xs={6}>
                            <div className="pull-right flex justify-center items-center">
                              {ticket.soldQuantity >= ticket.totalQuantity ? (
                                <>
                                  <h4 style={{ marginTop: 10 }} className="text-danger">Sold Out</h4>
                                </>
                              ) : (
                                <>
                                  <FiMinusCircle
                                    size={28}
                                    className="pointer"
                                    color="black"
                                    onClick={() => handleDecreaseQuantity(ticket)}
                                  />
                                  <div className="quantity text-black mt-0">{quantity}</div>
                                  <MdOutlineAddCircle
                                    size={28}
                                    className="pointer"
                                    color="black"
                                    onClick={() => handleIncreaseQuantity(ticket)}
                                  />
                                </>
                              )}

                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <span className="ticket-price">${ticketPrice.toFixed(2)}</span>
                          {quantity > 0 && (
                            <span className="ticket-fee">
                              + Prosessing/Service Fee
                            </span>
                          )}
                          <span className="ticket-end-date">{ticket?.ticketDescription}</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
              : null}
          </Row>
        </div >
        <Col lg={5}>
          <Totals />
        </Col>
      </Row >
    )
  }

  return (
    <>
      <Button onClick={handleShow} variant="primary mt-2">Purchase Tickets</Button>
      <Modal
        
        dialogClassName="modal-90w"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        contentClassName="ticket-modal"
      >
        <Modal.Body ref={modalContentRef}>
          {step === 1 && (
            <>
              <CartScreen />
            </>
          )}
          {step === 2 && (
            <Row style={{ margin: 10 }} className='checkout-page'>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-3">
                      <a href="#!" onClick={handlePrevious} className="text-body flex items-center">
                        <MdArrowBack /> Back to Cart
                      </a>
                    </h5>
                    <hr />
                    <h3 className="text-black">Billing Information</h3>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control className='customInput' disabled={isLoading} name="first_name" onChange={(e) => handleInputChange(e)} type="text" placeholder="First Name" value={inputValues.first_name} />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control disabled={isLoading} name="last_name" onChange={(e) => handleInputChange(e)} type="text" placeholder="Last Name" value={inputValues.last_name} />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control disabled={isLoading} name="email" onChange={(e) => handleInputChange(e)} type="email" placeholder="Enter email" value={inputValues.email} />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h3 className='text-black mt-20'>Payment Information</h3>
                    <div className="card-element-container">
                      <Form.Control
                        disabled={isLoading}
                        as={CardElement}
                        options={{ style: cardElementStyle }}
                      />
                    </div>
                  </Col>
                  <Col className='text-center' md={12}>
                    <img className='margin-top-20' src={stripePaymentModthod} width={150} alt="methods" />
                    <div>
                      <p>
                        <a href='https://stripe.com/legal/ssa' target='_blank' rel='noreferrer'>
                          Stripe Terms and Conditions
                        </a>
                      </p>

                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Totals />
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TicketCart