import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import moment from 'moment';



const PhotoGallery = ({ title, details, images, date }) => {
  const [index, setIndex] = useState(-1);
  console.log(images)
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length || 0;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const onInit = () => {
    console.log('lightGallery has been initialized');
  };


  return (
    <>
      <Container>
        <Row>
          <Col className="mx-auto margin-bottom-50 margin-top-70 text-center" md="12">
            <h2 style={{ textTransform: 'uppercase', fontSize: 48, fontFamily: 'mainFont' }} className="display-3 margin-top-30 text-white">{title}</h2>
            <h4 style={{ fontFamily: 'clubx', fontSize: 24, }} className="display-3 margin-top-30 text-white">{moment(date).format('dddd, MMM D YYYY')}</h4>
            <p className="lead">
              {details}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='margin-bottom-30'>
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
            >

              {images ? (
                <>
                  {images.map((item, key) => (
                    <a className="image-container" key={key} href={item}>
                      <img alt="Club X" src={item} className="img-responsive" />
                    </a>
                  ))}
                </>
              ) : null}
            </LightGallery>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PhotoGallery