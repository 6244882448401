import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/css';
import 'swiper/css/autoplay';

export default function HeaderSwiper({ events }) {

  return (
    <>
      <Swiper
        modules={events.length > 1 ? [Autoplay] : null}
        allowSlideNext={events.length > 1 ? true : false}
        allowSlidePrev={events.length > 1 ? true : false}
        pagination={false}
        centeredSlides={true}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        style={{ height: '100%', overFlow: 'none', objectFit: 'cover' }}
        className="swiper-container"
      >
        {events.map((data, index) => (
          <SwiperSlide key={index}>
            <img style={{ objectFit: 'fill' }} src={data.promo.banner} alt={''}></img>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
