import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'
import PhotoGallery from '../../components/PhotoGallery'
import { getServer } from '../../services/server'
import { toast } from 'react-toastify'

export default function Album() {
  const { slug } = useParams();
  const [pageData, setPageData] = useState();
  const [nothingToSee, setNothingToSee] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getServer(`/galleries/byslug/${slug}`);
      if (response?.items) {
        setPageData(response);
      } else {
        toast('No photos at this time.', {
          type: 'warning',
          theme: 'dark',
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNothingToSee(true);
      }
    }
    fetchData();
  }, [slug]);

  if (nothingToSee) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <NavBar />
      {pageData ? (
        <>
          {pageData?.items && (
            <PhotoGallery
              title={pageData?.name}
              date={pageData?.gallery_date}
              // details={data.details}
              images={pageData?.items}
            />
          )}
        </>
      ) : null}

      <Footer />
    </>
  )
}