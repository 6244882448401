import React, { useContext, useEffect, useState } from 'react'
import { getServer } from '../../services/server';
import { useParams } from 'react-router-dom';
import { CompanyContext } from '../../contexts/CompanyContaxt';
import BasicHeader from '../../components/headers/BasicHeader';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import {Col, Container, Row } from 'react-bootstrap';
import Ticket from '../../components/Ticket';

export default function Conformation() {
  const company = useContext(CompanyContext);
  const { user, event } = useParams();
  const [ticketData, setTicketData] = useState([]);

  useEffect(() => {
    async function getCompanyData() {
      const response = await getServer(`/tickets/getUserTickets/${company._id}/${user}/${event}`);
      console.log(response)
      if (response.length > 0) {
        setTicketData(response);
      };
    }

    getCompanyData()
  }, [company._id, event, user]);

  return (
    <>
      <NavBar />
      <BasicHeader byline={`${ticketData[0]?.event?.name} Ticket Confirmation`} size={48} headerimage={ticketData[0]?.event?.promo?.banner} />
      <div className="spacer"></div>
      <Row>
        <Col className="ml-auto mr-auto text-center">
          <h1 style={{ fontFamily: 'mainFont', fontSize: 65 }} className="title text-white">
            Purchased Tickets
          </h1>
        </Col>
        <Col md={12} className="ml-auto mr-auto text-center">
          <h1 style={{ fontSize: 16 }} className="title text-white">
            We emailed your receipt and link to get back to this page. Please check your spam folder if you do not see it.
          </h1>
        </Col>
      </Row>



      <Container>
        <Row>
          {ticketData.length > 0 ? ticketData.map((ticket, index) => (
            <Col key={index} md={4}>
              <Ticket ticket={ticket} company={company} bgColor={index % 2 === 0 ? '#1f2251' : '#1f2252'} />
            </Col>
          )) : null}
        </Row>
      </Container>

      <div className="spacer"></div>
      <div className="spacer"></div>
      <Footer />
    </>
  )
}
