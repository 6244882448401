import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../contexts/CompanyContaxt';
import { getServer } from '../../services/server';
import NavBar from '../../components/NavBar';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import { Button } from 'reactstrap';
import BasicHeader from '../../components/headers/BasicHeader';
import Subscribe from '../../components/Subscribe';


const Organizations = () => {
  const company = useContext(CompanyContext);
  const [pageData, setPageData] = useState([]);


  useEffect(() => {
    async function getData() {
      try {
        const response = await getServer(`/organizations/getByCompany/${company._id}`);
        setPageData(response);
      } catch (err) {
        console.log(err);
      }
    }
    getData();
  }, [company]);



  return (
    <>
      <NavBar />
      <BasicHeader
        title="Upcoming Events"
        headerimage={'https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1703798653509_K79A4266_Large.jpeg'}
        byline="Pick by Organization"
        // logo={"https://mirrorcorp.s3.us-west-2.amazonaws.com/logos/logoDark.png"}
      />
      <Row>
        {pageData && Array.isArray(pageData) && pageData.length > 0 ? pageData.map((org, key) => (
          <Col
            style={{
              borderRight: key === 0 ? '1px solid black' : 'none'
            }}
            xs={6}
            md={6}
            key={org.name}
            className="margin-top-50 margin-bottom-50"
          >
            <Col>
              <div style={{ fontFamily: 'mainFont' }} className='text-center'>
                <div style={{ fontSize: 15, color: '#e14eca' }}>{org.dates}</div>
                <div style={{ fontSize: 30 }}>{org.name}</div>
                <h4 style={{ fontFamily: 'clubx' }} className='org-biLine text-white'>{org.biLine}</h4>

              </div>
            </Col>
            <Col>
              <div className="logo-wrapper">
                <Link to={`events/${org.slug}`}>
                  <img style={{ maxHeight: 300 }} src={org.logo} alt={org.name} className="logo" />
                  <div className='text-center'>
                    <Button size="sm" className="btn-simple" color="primary">Upcoming Events</Button>
                  </div>
                </Link>
              </div>
            </Col>
          </Col>
        )) : null}
      </Row>
      <Row>
        <Col>
          <Subscribe />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Organizations;