function round(num) {
  return Math.round(num * 100) / 100;
}

function buildTicketFees (tickets, stewieServiceCharge = 3.5, stripeProcessingFee = 0.029, stripeProcessingFixedFee = 0.3) {
  const ticketCharges = tickets.reduce((sum, ticket) => sum + ticket.price, 0)
  const serviceCharges = tickets.length * stewieServiceCharge;
  const unroundedTotal = (ticketCharges + serviceCharges + stripeProcessingFixedFee) / (1 - stripeProcessingFee);
  const total = round(unroundedTotal);
  const processingFees = round(unroundedTotal - ticketCharges - stewieServiceCharge)

  return {
    total,
    breakdown: {
      tickets: ticketCharges,
      serviceCharges,
      processingFees,
    },
    tickets: tickets.map(ticket => ({
      ...ticket,
      serviceCharge: stewieServiceCharge,
      processingFee: (((ticket.price + stewieServiceCharge + (stripeProcessingFixedFee / tickets.length)) / (1 - stripeProcessingFee)) - ticket.price - stewieServiceCharge),
      total: ((ticket.price + stewieServiceCharge + (stripeProcessingFixedFee / tickets.length)) / (1 - stripeProcessingFee))
    }))
  }
}

export default buildTicketFees;